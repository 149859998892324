/* eslint-disable no-alert */
<template>
  <div class="user">
    <el-row
      type="flex"
      class="row-bg"
      justify="space-between"
      align="center">
      <el-col :span="12">
        <div class="title">
          <h1>
            Пользователь
          </h1>
        </div>
      </el-col>
    </el-row>
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="right-text">
            <el-button
              :type="!isEdit ? 'primary' : 'warning'"
              size="Default"
              :icon="!isEdit ? 'el-icon-edit' : 'el-icon-close'"
              @click="editHandler">
              {{ !isEdit ? 'Редактировать' : 'Отмена' }}
            </el-button>
          </div>
        </el-col>
      </el-row>
      <br>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form
            ref="ruleForm"
            :model="user"
            :rules="rules"
            label-width="0"
            class="demo-ruleForm">
            <el-descriptions
              class="margin-top"
              :column="2"
              size="medium"
              border>
              <!-- <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  id
                </template>
                {{user.id}}
              </el-descriptions-item> -->
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user" />
                  Имя
                </template>
                <div
                  v-if="!isEdit"
                  class="user-description-item">
                  {{ user.first_name }}
                </div>
                <div v-else>
                  <el-form-item prop="first_name">
                    <el-input
                      v-model="user.first_name"
                      placeholder="Введите имя"
                      :size="size" />
                  </el-form-item>
                </div>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-mobile-phone" />
                  Телефон
                </template>
                <div
                  v-if="!isEdit"
                  class="user-description-item">
                  {{ user.phone }}
                </div>
                <div v-else>
                  <el-form-item prop="phone">
                    <el-input
                      v-model="user.phone"
                      v-mask="'+7(###)###-##-##'"
                      placeholder="Введите телефон"
                      type="tel"
                      :size="size" />
                  </el-form-item>
                </div>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user" />
                  Фамилия
                </template>
                <div
                  v-if="!isEdit"
                  class="user-description-item">
                  {{ user.last_name }}
                </div>
                <div v-else>
                  <el-form-item prop="last_name">
                    <el-input
                      v-model="user.last_name"
                      placeholder="Введите фамилию"
                      :size="size" />
                  </el-form-item>
                </div>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-message" />
                  Email
                </template>
                <div
                  v-if="!isEdit"
                  class="user-description-item">
                  {{ user.email }}
                </div>
                <div v-else>
                  <el-form-item prop="email">
                    <el-input
                      v-model="user.email"
                      placeholder="Введите email"
                      :size="size" />
                  </el-form-item>
                </div>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user" />
                  Отчество
                </template>
                <div
                  v-if="!isEdit"
                  class="user-description-item">
                  {{ user.middle_name }}
                </div>
                <div v-else>
                  <el-form-item prop="middle_name">
                    <el-input
                      v-model="user.middle_name"
                      placeholder="Введите отчество"
                      :size="size" />
                  </el-form-item>
                </div>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-lock" />
                  Пароль
                </template>
                <div
                  v-if="!isEdit"
                  class="user-description-item">
                  ************
                </div>
                <div v-else>
                  <el-form-item prop="password">
                    <el-input
                      v-model="user.password"
                      placeholder="Введите пароль"
                      :size="size" />
                  </el-form-item>
                </div>
              </el-descriptions-item>
            </el-descriptions>
          </el-form>
        </el-col>
      </el-row>
      <br>
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="right-text">
            <el-button
              type="primary"
              size="Default"
              :disabled="isDisabledEditBtn"
              icon="el-icon-document-checked"
              @click="editUser">
              Сохранить
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import isEqual from 'lodash.isequal';

export default {
  name: 'User',
  data() {
    return {
      isEdit: false,
      size: 'mini',
      initUser: {
        id: '123123123',
        email: 'test@mail.ru',
        email_confirmed: 'test@mail.ru',
        phone: '+79209992233',
        first_name: 'Иван',
        last_name: 'Иванов',
        middle_name: 'Иванович',
        password: '2213weq23x12312',
      },
      user: {},
      rules: {
        first_name: [
          { required: true, message: 'Введите имя', trigger: 'change' },
          {
            min: 3, max: 15, message: 'Длинна должна быть от 3 до 15', trigger: 'change',
          },
        ],
        last_name: [
          { required: true, message: 'Введите фамилию', trigger: 'change' },
          {
            min: 3, max: 15, message: 'Длинна должна быть от 3 до 15', trigger: 'change',
          },
        ],
        middle_name: [
          { required: true, message: 'Введите отчество', trigger: 'change' },
          {
            min: 3, max: 15, message: 'Длинна должна быть от 3 до 15', trigger: 'change',
          },
        ],
        password: [
          { required: true, message: 'Введите пароль', trigger: 'change' },
          {
            min: 6, max: 15, message: 'Длинна должна быть от 6 до 30', trigger: 'change',
          },
        ],
        email: [
          { required: true, message: 'Введите email', trigger: 'blur' },
          {
            type: 'email', message: 'Пожалуйста введите email адресс', trigger: ['blur', 'change'],
          },
        ],
        phone: [
          { required: true, message: 'Введите phone', trigger: 'change' },
          {
            min: 16, max: 16, message: 'Пожалуйста введите телефон верно', trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
  computed: {
    isDisabledEditBtn() {
      return isEqual(this.user, this.initUser);
    },
  },
  mounted() {
    const trimNum = this.initUser.phone.slice(1);
    this.initUser.phone = this.setPhoneMask(trimNum);
    this.user = { ...this.initUser };
  },
  methods: {
    submit() {
    },
    setPhoneMask(number) {
      const pattern = new RegExp(`(^\\d{${number.length % 10}})(\\d{3})(\\d{3})(\\d{2})(\\d{2}$)`);
      return number.replace(pattern, '+$1($2)$3-$4-$5');
    },
    editHandler() {
      // eslint-disable-next-line no-unused-expressions
      !this.isEdit ? this.editUser() : this.resetUserData();
    },
    resetUserData() {
      this.user = { ...this.initUser };
      this.isEdit = false;
    },
    editUser() {
      this.isEdit = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // eslint-disable-next-line
          alert('submit!');
          return true;
        }
        return false;
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="scss">
  .user {
    .el-descriptions-item__cell {
      width: 25%;
      // display: flex;
      align-items: flex-start;
      vertical-align: top;
    }
    .el-form-item {
      // margin: 0;
      &__content {
        line-height: 100%;
        height: 100%;
      }
    }
    .user-description-item {
      // height: 28px;
      padding: 0 15px;
      display: flex;
      align-items: center;
    }
  }
</style>
